import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/review.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This past Friday, Monster Hunter: Rise came out. Like every normal forty year old, I took the day off work to binge play the game. Over the course of the weekend, I managed to play it for seventeen hours. This isn't really enough time to formulate all of my thoughts on the game, but I figured I'd lay down some of my initial thoughts and feelings on the game at launch.`}</p>
    <p>{`First and foremost, the game is `}<em parentName="p">{`FUN`}</em>{`. I did roughly 40 hunts in those seventeen hours, all but two of which were with the hunting horn. Let me tell ya, if the hunting horn has always been like this, I'm not sure I'd have ever played another weapon. The way they've streamlined the song list and performance aspect goes a long way to making this weapon approachable. The Magnificent Trio and Infernal Melody add a nice bit of depth and a healthy dose of numbers on the screen. I have not messed with any of the switch skills so far. I've only unlocked two: the "traditional" recital mode and the silkbind cocoon thingy. Neither of these options seem particularly appealing as the new combat mode recital is part of the reason I switched and there's just no way I'd replace Earthshaker, the numbers are just too big. If you've never given the hunting horn a shot, I suggest you try it now. It's not the weapon you remember.`}</p>
    <h4>{`Best New Monster: Bishaten`}</h4>
    <p>{`Outside of the Great Izuchi, who's job is to be the early game punching bag bird wyvern, all of the new monsters I've faced have been pretty great. Bishaten, however, takes the cake and all of the fruit in the jungle. He's got so much personality. He hangs from the walls with his tail like a monkey or stands on the tail like a far more agile and far more dangerous Great Maccao. He'll whirlwind dash at you or just somersault all over the place. The food he throws at you can have multiple effects and you can pick up some of fruit he drops either to eat to regain some health or throw back in his face. It's just a fun fight.`}</p>
    <h4>{`Second Best New Monster: Tetranadon`}</h4>
    <p>{`He's a giant sumo platypus, what else could you ask for?`}</p>
    <h4>{`Best New Feature: Mobility`}</h4>
    <p>{`I don't think the Monster Hunter team can ever take away Palamutes ever again. They are just way too useful for getting around. I absolutely love the wirebugs. I love the wirebug fall move to immediately get back on your feet after, and I love being able to get airborne whenever I want, even if the hunting horn doesn't have a great aerial attack. I start just about every skirmish by leaping off my palamute and bonking the monster in air.`}</p>
    <h4>{`Worst New Feature: The Poetry`}</h4>
    <p>{`I...can't stand this stuff. Every time you set out on a hunt against a new monster (or into a new environment) you get to watch a lovely cinematic, much as you always did. However this time you get, if you have the english voice over selected, someone trying to sound like Vincent Price read off some terrible poetry. When I eventually go to Diablos, the localization team really bent and twisted the words to rhyme dystopia with cornucopia. Sure those words rhyme, but the connecting thought was lame and really that's just the worst of it. I would have much rather had the cinematics with no voice over.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1035px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/734ad6090d4088f2079dc7e1a568e41c/eea4a/mhr-lagombi.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.37065637065637%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAv/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAFqpNJYTB//xAAZEAEAAwEBAAAAAAAAAAAAAAABAAISESH/2gAIAQEAAQUCphrzEtlgssvX2f/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABsQAAIDAAMAAAAAAAAAAAAAAAERAAIhEBJR/9oACAEBAAY/AutxvsF8Bgaa52f/xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhMXGB/9oACAEBAAE/IXxh8MSA0YQXgunstOxztuG1x//aAAwDAQACAAMAAAAQLB//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAcEAEBAAMAAwEAAAAAAAAAAAABEQAhMUFRYXH/2gAIAQEAAT8QVkXeJHlmXRC198X8zR+Q70aXJq4LPm8fVXtwpgoS5//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Lagombi Poetry",
            "title": "Lagombi Poetry",
            "src": "/static/734ad6090d4088f2079dc7e1a568e41c/af659/mhr-lagombi.jpg",
            "srcSet": ["/static/734ad6090d4088f2079dc7e1a568e41c/8356d/mhr-lagombi.jpg 259w", "/static/734ad6090d4088f2079dc7e1a568e41c/bc760/mhr-lagombi.jpg 518w", "/static/734ad6090d4088f2079dc7e1a568e41c/af659/mhr-lagombi.jpg 1035w", "/static/734ad6090d4088f2079dc7e1a568e41c/eea4a/mhr-lagombi.jpg 1280w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h4>{`Second Worst Feature: The Canteen Song`}</h4>
    <p>{`This really follows along the same lines as the poetry. It's just not for me. Once again, the animations as the palicos prepare your dango are fantastic, both at the Tea Shop and inside the gathering hub (the Tea Shop animation wins though). The song that you have to listen to though is just annoying and in this case there is no english version, only Japanese.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1035px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1267300c8b75b7c30aa99ce88cd71504/eea4a/mhr-canteen.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.37065637065637%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMCBP/EABYBAQEBAAAAAAAAAAAAAAAAAAMBAv/aAAwDAQACEAMQAAABluImo5Uz/8QAGxAAAgEFAAAAAAAAAAAAAAAAAQIhAxIiMTL/2gAIAQEAAQUCxVXIspQnYaAuv//EABYRAQEBAAAAAAAAAAAAAAAAAAARIf/aAAgBAwEBPwGMf//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/AUf/xAAbEAACAwADAAAAAAAAAAAAAAAAIQECERBB4f/aAAgBAQAGPwKsTXBs8Ns8F3x//8QAHBAAAgICAwAAAAAAAAAAAAAAASEAETFRQWGR/9oACAEBAAE/IXqT2KAveoaCBmCeQBQ6iBQlRmZnO5//2gAMAwEAAgADAAAAEOjP/8QAGBEAAgMAAAAAAAAAAAAAAAAAABEBMUH/2gAIAQMBAT8QirHo/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARIf/aAAgBAgEBPxDVnq//xAAbEAEAAwADAQAAAAAAAAAAAAABABExIUFhUf/aAAgBAQABPxDnAIVqi3Zzbc2JALxXaHT7BsSlQ3Q+RMB1TEHOkKHQclsDwp//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Dango",
            "title": "Dango",
            "src": "/static/1267300c8b75b7c30aa99ce88cd71504/af659/mhr-canteen.jpg",
            "srcSet": ["/static/1267300c8b75b7c30aa99ce88cd71504/8356d/mhr-canteen.jpg 259w", "/static/1267300c8b75b7c30aa99ce88cd71504/bc760/mhr-canteen.jpg 518w", "/static/1267300c8b75b7c30aa99ce88cd71504/af659/mhr-canteen.jpg 1035w", "/static/1267300c8b75b7c30aa99ce88cd71504/eea4a/mhr-canteen.jpg 1280w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Both of these make it so when I play in handheld mode, I have the volume all the way down, and when I'm docked I skip the show. It would have been nice to have more than one cutscene available to watch though. The Meowscular Chef had at least two different cutscenes to watch, both of which were excellent, but here there seems only to be the one.`}</p>
    <h4>{`Better Than I Thought It Would Be: Wyvern Riding`}</h4>
    <p>{`I didn't like this in the demo for a few reasons: the mounting thresholds for the target monster were absurdly high, it was not optional, damage dealt to mountable (or mounted) monsters was pitifully small, and the discarded monsters would just stomp on you. Based on my experience so far only one these has held fully true: it's still not optional. That aside, I've noticed that while my damage does take a small hit when hitting a mountable/mounted monster it doesn't seem like as big of a difference compared to the demo. Secondly, after I've hurled a monster into another it almost always gets up and runs away after the silk bindings are gone, eliminating my worry about having to fight multiple monsters all the time. Finally, while the target monster mounting threshold remains high, as you'd expect when any weapon can get airborne at anytime, I've had multiple hunts where I've managed to mount the target twice. Other roaming monsters still have laughable thresholds and I don't think it's taken more than a single silkbind attack to mount any of them.`}</p>
    <h4>{`A Decent Diversion: Rampage mode`}</h4>
    <p>{`I've only done two of these quests, one solo and one with a friend. All I can say is that it's vastly better than the Zorah Magdaros or Lao Shen Lung siege style quests. I'd just rather go beat on the monsters out in the field. So long as these things are not required frequently I think they'll be alright.`}</p>
    <h4>{`Issues`}</h4>
    <p>{`I haven't seen anything terribly back breaking yet. The only "bugs" I've seen have been related to the palamute riding. There have definitely been times where I've had to mash the B button to get off the palamute and sometime it seems like the dog wont jump right when I hit R2. You, your allies, and the large monsters themselves always seem permanently locked at 30 FPS which is great. I've never had a slow down yet, but you can tell that the team optimized away some of the fluidity of the other indigenous life. The remobras are a great example. When they are beating their wings you can tell how rough the animation is. It's noticeable while running around during an expedition or chasing down the target monster but I can mostly ignore this because all I care about is the action during the hunt.`}</p>
    <h4>{`Final Thoughts`}</h4>
    <p>{`I absolutely love this game so far and can't wait to really sink my teeth into all my other favorite weapons: Switch Axe, GS, GL, Lance, Hammer, and LBG. Anyway, here's me after seventeen hours.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1035px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/89b8632a098b737f5a24882072bf71d9/eea4a/mhr-hunter.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.37065637065637%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAQF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQD/2gAMAwEAAhADEAAAAa5CMhqB/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAECAxMi/9oACAEBAAEFAs9ZcXt4YoQok5wf/8QAFhEAAwAAAAAAAAAAAAAAAAAAARAR/9oACAEDAQE/AYF//8QAFREBAQAAAAAAAAAAAAAAAAAAEBL/2gAIAQIBAT8Bo//EABwQAAEDBQAAAAAAAAAAAAAAAAABAiEQETEyQf/aAAgBAQAGPwK7oUd2Yrg1P//EABoQAAMBAQEBAAAAAAAAAAAAAAERIQAxEHH/2gAIAQEAAT8hHAQqzgsvVpbq5EFX74hdxv/aAAwDAQACAAMAAAAQkB//xAAVEQEBAAAAAAAAAAAAAAAAAAAQYf/aAAgBAwEBPxCR/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oACAECAQE/EKXX/8QAGhAAAgMBAQAAAAAAAAAAAAAAAREAITFBUf/aAAgBAQABPxAs5Ig29qOMkwE4Fp9F5GJuXOUwq0JCW2dMc2325//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "My Hunter",
            "title": "My Hunter",
            "src": "/static/89b8632a098b737f5a24882072bf71d9/af659/mhr-hunter.jpg",
            "srcSet": ["/static/89b8632a098b737f5a24882072bf71d9/8356d/mhr-hunter.jpg 259w", "/static/89b8632a098b737f5a24882072bf71d9/bc760/mhr-hunter.jpg 518w", "/static/89b8632a098b737f5a24882072bf71d9/af659/mhr-hunter.jpg 1035w", "/static/89b8632a098b737f5a24882072bf71d9/eea4a/mhr-hunter.jpg 1280w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      